.Root {
  padding: 4px;
  width: var(--bt-cms-search-panel-width);
  margin: auto;
}

.Popover {
  //width:450px;
  //min-width: 450px;
  //background: red;
}

.menuPanel {
  pointer-events: all;
  padding: 0.5rem;
  max-width: 330px;
}

.filterPanel {
  pointer-events: all;
  padding: 0.5rem;
  min-width: 400px;
  max-width: 400px;
}

.filterInput {
  font-size: 10px;
}

.filterHeader {
  margin-block-end: 0.5rem;
  font-size: 1rem;
}

.SavePanel {
  pointer-events: all;
  text-align: end;
  padding: 5px;
}

.Chip {
  margin-block: 0.2rem;
  margin-inline: 0.2rem;
  border-radius: 4px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Chip svg {
  color: black !important;
}

.ChipExclude svg {
  color: #930707 !important;
}

.ChipExclude {
  margin-block: 0.2rem;
  margin-inline: 0.2rem;
  border-radius: 4px;
  border-color: #930707;
}

.ChipDisable {
  margin-block: 0.2rem;
  margin-inline: 0.2rem;
  border-radius: 4px;
  border-color: gray;
  text-decoration: line-through;
  color: gray;
  border-color: lightgrey;
}

.ChipExcludeLabel {
  color: #930707;
}


.AddFilterLabel {
  color: var(--bt-cms-color-primary);
  cursor: pointer;
  text-decoration: none;
  margin-inline-start: 0.8rem;
  font-size: 0.7rem;
  word-break: keep-all;
  white-space: nowrap;
}

.AddFilterLabel:hover {
  color: var(--bt-cms-color-primary);
  text-decoration: underline;
  margin-inline-start: 0.8rem;
  font-size: 0.7rem;
  word-break: keep-all;
  white-space: nowrap;
}