.AppBar {
  height: var(--bt-cms-appbar-page-height) !important;
  min-height: var(--bt-cms-appbar-page-height) !important;
  color: black;
  z-index: var(--bt-cms-appbar-z-index)!important;;
  top: calc(var(--bt-cms-appbar-user-height)) !important;
  left: calc(var(--bt-cms-sidebar-move-left));
  //bottom: var(--bt-cms-appbar-user-height) !important;
  background-color: white !important;
  width: calc(100% - var(--bt-cms-sidebar-move-left));
  transition: all 0.3s ease-in;
  overflow: hidden;
}

.Toolbar {
  background-color: white;
  height: var(--bt-cms-appbar-page-height) !important;
  min-height: var(--bt-cms-appbar-page-height) !important;
}

.Breadcrumbs {
  min-width: 300px;
}

@media screen and (max-width: 450px){
  .Breadcrumbs {
    display: none;
  }
}

//.Sidebar {
//  background-color: #005693;
//  color: #fff;
//  position: fixed;
//  bottom: 0px;
//  left: 0px;
//  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 2px);
//  width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
//  max-width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
//  z-index:  calc(var(--bt-cms-drawer-z-index) - 2);
//  overflow-y: auto;
//  overflow-x: hidden;
//}
//
.Drawer {
  background-color: var(--bt-cms-color-drawer);//#004475;
  color: #fff;
  position: fixed;
  bottom: var(--bt-cms-footer-height);
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 2px);
  width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
  max-width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
  z-index:  var(--bt-cms-drawer-z-index);
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s ease-in;
}

.Drawer li {
  color: #e5e5e5;
  height: 2.7rem;
  padding-inline-start: 10px;
}

.SidebarTitle {
  margin: auto;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0;
}

.DrawerTopicTitle {
  margin: auto;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 10px;
}

.DisablePanel {
  pointer-events: none;
}
//.DrawerBackdrop {
//  position: fixed;
//  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 2px);
//  bottom: 0px;
//  left: 0px;
//  right: 0px;
//  background-color: transparent;
//  z-index: var(--bt-cms-drawer-backdrop-z-index);
//}






