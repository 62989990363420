.Root {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 4rem;
}

.Root  * {
  box-sizing: border-box;
}

.RootGrid {
  display: grid;
  margin: auto;
}



.ImageCarouselPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  clip: auto;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
}

.ImageRegularPanel {
  clip: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  align-self:center;
}

.ImageWovenPanel:nth-child(even) {
  height: 70%;
  width: 100%;
  margin: auto;
}



.Image {
  width: 100%;
  height: 100%;
}

.Caption {
  position: absolute;
  bottom: 0px;
  left:0px;
  right: 0px;
}

.AnchorPanel {
  position: relative;
}


.NextButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  width: 50px;
  right: 0;
  top: 20%;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
}

.NextButton:hover {
  opacity: 1;
}

.BackButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  width: 50px;
  top: 20%;
  left: 0;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
}

.BackButton:hover {
  opacity: 1;
}

