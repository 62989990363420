.DefaultFontColor {
  color: #444446;
}


.Chip {
  margin-inline-end: 8px;
  margin-block: 2px;
  font-size: 10px;
  padding-inline: 0px;
  cursor: pointer;
}

.Chip span {
  padding-inline: 6px;
}

.Popover {
  padding: 10px;
  pointer-events: auto;
}

.PopoverActionPanel {
  text-align: right;
}

.AddButton {
  padding-inline: 0px;
  font-size: 10px;
}