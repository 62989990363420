@import "src/config/Common.module";

.LoginBackground {
  background: linear-gradient(var(--bt-cms-color-primary-normal) 10%,var(--bt-cms-color-primary-dark) 90%),url(https://app.billtrust.com/assets/layout/images/bk.png) 50% no-repeat #f7f7f7;
  //background: linear-gradient(#00764a, 10%,#003035 90%),url(https://app.billtrust.com/assets/layout/images/bk.png) 50% no-repeat #f7f7f7;
  background-blend-mode: overlay,normal;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  overflow-y: auto;
  display: flex;
  background-size: cover;
}

.LoginPanel {
  width: 23rem;
  margin: auto;
  border-radius: 0.5rem;
  background-color: white;
  text-align: center;
}

.LoginPanel img {
  height: 2.8rem;
  margin-top: 1.5rem;
  margin-bottom: 0.7rem;
}

.LoginSubPanel {
  background: linear-gradient(
                  160deg
          ,#f4f4f4,hsla(0,0%,95.7%,.7));
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  padding-top: 2rem;
  position: relative;
  margin:16px;
  color: darkgray;
  cursor: pointer;
}

.LoginSubPanelLegend {
  background: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
  color: #4d6069;
  font-weight: 700;
  font-size: 14px;
  left: 50%;
  line-height: 1.2;
  padding: 0 8px 4px;
  position: absolute;
  top: -1px;
  transform: translateX(-50%);
}

.ViewIcon {
  position: absolute;
  transform: translate(-2rem,1.5rem);
}

.LoginPanel h4 {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
}
