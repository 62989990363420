.EmptyBorderPanel {
  border:none;
  padding-top: 0rem;
  position: relative;
  margin-block: 0rem;
  margin-inline: 0rem;
  padding: 3px;
}

.Title {
  padding-block-end: 14px;
  font-size: 12px;
  font-weight: bold;
}

.BorderPanel {
  background: linear-gradient(
    160deg
  ,#f4f4f4,hsla(0,0%,95.7%,.7));
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  padding-top: 2rem;
  position: relative;
  margin-block:0.5rem;
  margin-inline: 0rem;
  color: darkgray;
  cursor: pointer;
}

.BorderPanelLegend {
  background: #fff;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
  color: #4d6069;
  //font-size: 0.3rem;
  //font: 700 14px Fira Sans,Arial,sans-serif;
  font-size: 14px;
  font-weight: 700;
  left: 3px;
  line-height: 1.2;
  padding: 0 8px 4px;
  position: absolute;
  top: -1px;
}
