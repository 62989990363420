.Input input {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  padding-inline-end: 10px;
  height:14px;

}


.Input > div {
  border-radius: 6px;
}

.UnitButton {
  padding: 0px;
  width: 50px;
}

.UnitButton:hover {
  background-color: unset;
  color: var(--standard-jade-500);
  font-weight: 700;

}

.Unit {
  font-size: 12px;
  font-weight: 500;
  padding-inline-start: 10px;
}

.UnitEmpty {
  font-size: 12px;
  font-weight: 500;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}

.Caret {
  font-size: 1rem;
  padding-inline-end: 5px;
}

.Separator {
  padding-block: 4px;
  width: 50px;
  text-align: center;
  border-left: 1px dashed var(--standard-coal-300);
}