//
//.Table {
//  width: 80%;
//  margin: auto;
//}
//
//@media screen and (max-width: 500px) {
//  .Table {
//    width: 90%;
//    margin: auto;
//  }
//}

.SearchInput {
  font-size: 14px;
  height: 24px;
  padding-inline: 10px;
  margin: 0px;
  text-align: end;
}

