.ListTitle {
  color: var(--standard-coal-400);
}
.ListIcon {
  min-width: unset;
}

.ListLabel {
  font-size: 14px;
  color: var(--standard-coal-500)
}

.SearchPanel {
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 100;
}

@media screen and (max-width: 400px) {
  .SearchPanel {
    position: relative;
    float: left;
    z-index: 100;
    background-color: white;
    opacity: 0;
  }
}