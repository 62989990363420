.Root {

}

.Container {
  position: absolute;
  top:  0px;
  left: 0px;
  right: 0px;
  height: fit-content;
  overflow: hidden;
  touch-action: none;
}

.ScrollPanel {
  white-space: nowrap;
  margin: auto;
  cursor: pointer;
  text-align: center;
}

.Panel {
  display: inline-block;
  transition: all 0.3s ease-in;
}


.NextButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  //right: 0;
  //top: 20%;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
  z-index: 1000;
}

.NextButton:hover {
  opacity: 1;
}

.BackButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  //top: 20%;
  //left: 0;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
  z-index: 1000;
}

.BackButton:hover {
  opacity: 1;
}