.Panel {
  border-radius: 0.2rem;
  text-align: left;
  color: white;
}

.IconPanel {
  margin: auto;
  text-align: center;
  height: 100%;
  width: 100%;
}

.IconSize {
  font-size: 2rem;
}

.Title {
  font-size: 0.8rem;
  line-height: 1rem;
  padding-block-end: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-inline-start: 0.3rem;
  padding-block-end: 0.5rem;
}

.Value {
  font-weight: bolder;
  font-size: 1.5rem;
  padding-block-start: 0.8rem;
  padding-inline-start: 0.3rem;
}

@media screen and (max-width: 500px) {
  .Value {
    font-size: 1rem;
  }

  .Title {
    font-size: 0.5rem;
  }

  .IconSize {
    font-size: 1.5rem;
  }
}