.Container {
  text-align: center;
  cursor: pointer;
  margin: auto;
  min-height: 128px;
  height: 100%;
}

.Container:hover {
  color: black;
}

.ItemsContentPanel {
  padding: 10px;
  pointer-events: none;
  height: 100%;
}

.ContentTitle {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  text-align: center;
  background-color: white;
  color: #282c34;
}

.ContentTitleHighlight {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  text-align: center;
}

.ContentBody {
  padding-inline-start: 5px;
  padding-block-start: 10px;
  text-align: start;
  font: normal normal normal 14px/21px Inter;
  letter-spacing: 0px;
  color: #555555;
}

.Icon span {
  font-size: 18px;
  padding-inline-end: 0px;
  line-height: 21px;
  color: var(--bt-cms-color-primary-normal);
}

.Title {
  text-align: start;
  font: normal normal medium 16px/32px Inter;
  color: black;
  padding-inline-start: 10px;
}
