.TopIconButton {
  width:40px;
  height: 40px;
  color: var(--standard-coal-300);
}

.TopIconButton:hover {
  color: var(--standard-jade-500);
}

