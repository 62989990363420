.Root {
  width: 80%;
  margin: auto;
}

.Breadcrumbs {
  min-width: 300px;
  width: 80%;
  color: var(--standard-color-555555);
}

.BreadcrumbSeparator {
  font-size: 16px;
  line-height: 18px;
}

.Breadcrumbs a {

}

.Title {
  font-size: 24px;
  font-weight: 400;
  color: #000000DE;
  padding-block-start: 16px;
  padding-block-end: 0px;
}

.Title span:nth-child(2) {
  padding-inline-start: 16px;
}

.Desc {
  color: var(--standard-color-555555);
  font-size: 14px;
  padding-block-start: 8px;
  padding-block-end: 24px;
}