.Root {
  position: absolute;
  padding-block-start: 2rem;
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 4px) !important;
  bottom: var(--bt-cms-footer-height);
  width: calc(100% - var(--bt-cms-sidebar-move-left));
  border: 0px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.3s ease-in;
  background-color: var(--bc-background-color);
  margin-left: calc(0px + var(--bt-cms-sidebar-move-left));
}

.Container {
  max-width: 80%;
  min-width: 300px;
  width: 80%;
  margin: auto;
}

.Container2 {
  @extend .Container;
  padding-top: 10px;
}
.SubContainer {
  padding-block-start: 0.5rem;
  padding-inline-start: 50px;

}

.Breadcrumb {
 padding-bottom: 2rem;
}

.DrilldownInfo {
  float: left;
  width: calc(100% - 3.5rem);
}

.DrilldownAction {
  float: right;
  padding-inline-start: 0.7rem;
  display: flex;
  flex-direction: column;
}

.DrilldownActionItem {

}

.Icon {
  padding-inline-end: 0.3rem;
}

.SceneEditActionPanel {
  min-width: 500px !important;
  max-width: 500px  !important;
  position: absolute !important;
  right: 10px;
  justify-content: flex-end;
}

.Title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  padding-block-start: 1rem;
}

.EmptyPanel {
  height: 100px;
}
