.Header {
  font-size: 14px;
  line-height: 3rem;
  text-align: start;
  margin-block-end: 1rem;
  padding-inline-start: 0.5rem;
  font-weight: bolder;
  text-transform: capitalize;
  color: var(--standard-coal-500);
}
.SelectBox {
  margin: 5px;
}

.Tabs {

}

.Tab {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  padding-block: 0px;
  min-width: unset;
  text-align: start;
}

.AccordingPanel {
  padding-inline: 0px;
}

.AccordingDetailPanel {
  padding-inline: 14px;
}


.AccordingLabelPanel {
  max-height: 40px;
  min-height: unset !important;
}

.AccordingLabel {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}


.AccordingLabelActive {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--standard-jade-500);
}

.AdvanceEditorTitle {
  padding-inline-start: 12px;
  font-size: 12px;
  font-weight: bold;
}

.AdvanceEditorSubTitle {
  padding-inline-start: 12px;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  padding-block-start: 5px;
  padding-block-end: 10px;
  color: #66737A;
}

.AdvanceEditorPanel {
  border-radius: 4px;
  border: 1px solid lightgrey;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  box-sizing: border-box;
}

.SuggestIcon {
  font-size: 1.2rem;
}

.SettingPanel {
  overflow-y: auto;
  height: calc(100% - var(--bt-cms-appbar-user-height) - var(--bt-cms-appbar-page-height) - var(--bt-cms-aappbar-action-height) );
}

.CodeParentPanel {
  //height: calc(100% - 3.2rem);
  height: calc(100% - 5.6rem);
}

.CodePanel {
  //height: calc(100% - 2.2rem) !important;
  height: calc(100% - 2.2rem - 10px) !important;
}

.CodeParentPanelTheme {
  height: calc(100% - 5.6rem - 10px);
}

.CodePanelTheme {
  height: calc(100% - 2.2rem - 10px) !important;
}