.Root {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 150px;
}

.Container {
  flex: 1 1
}


.BreadCrumb {
  display: block;
  list-style: none;
  padding: 0px;
}

.BreadCrumb li {
  display: inline-block;
}

.FAQItem {
  margin-block-start: 10px;
}

.FAQItemTitle {
  padding-block: 5px;
  font-weight: 700;
}

.FAQItemImage {
  text-align: center;
  padding-block-start: 20px;
  padding-block-end: 10px;
}

.GridPanel {
  padding-block: 20px;
}

.GridItemImage {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  //height: 200px;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.GridItemImage img {
  width: 90%;
  height: auto;
}

.GridItemTitle {

}

.GridItemTitle a {
  color: unset;
}

.SlickPanel {
  margin: 0 0 30px 0;
  padding: 0px 40px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 4px rgba(0,0,0,.2);
  -o-box-shadow: 0 1px 4px rgba(0,0,0,.2);
  zoom: 1;
}
.SlickItem {
  padding: 10px;
}

.SlickItem img {
  width: 80%;
  height: auto;
}