.Root {
  position: relative;
  height: 150px;
}

.StatPanel {
  max-width: 920px;
  margin: auto;
}

@media screen and (max-width: 900px){
  .StatPanel {
    max-width: 500px;
  }
}

.DAMPanel {
  border-radius: 0.5rem;
  margin: auto;
  margin-block: 1.5rem;
  max-width: 1000px;
  width: var(--bt-cms-dam-panel-width);
  padding: 0.5rem;
}

.DAMPanelDragOver {
  border-style: dashed;
  border-width: 2px;
  border-radius: 0.5rem;
  margin: auto;
  margin-block: 1.5rem;
  max-width: 1000px;
  width: var(--bt-cms-dam-panel-width);
  padding: 0.5rem;
}


.DAMBreadcrumb {
  padding-block: 0.5rem;
  padding-inline-start: 1rem;
}

.DAMActionPanel {
  float: right;
  height: 2rem;

}

.DAMSubFolder {
  padding: 5px;
  padding-block-start: 20px;
}

.DAMSubFolderTitle {
  font-size: 0.9rem;
  color: gray;
  padding-inline-start:5px;
  font-weight: bolder;
}

.DAMButton {
  padding: 5px;
  font-size: 0.8rem;
  margin: 2px;
}

@media screen and (max-width: 900px){
  .DAMPanel {
    max-width: var(--bt-cms-dam-panel-width);
  }
}

@media screen and (max-width: 500px){
  .DAMPanel {
    max-width: 95%;
  }
}