.Root {
  position: relative;
  min-height: 200px;
  height: 200px;
  width: 100%;
  border: 1px solid var(--standard-coal-200);
  border-radius: 3px;
  cursor: pointer;
  color: var(--standard-coal-500);
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5px;
  user-select: none;
}

.CloseIcon {
  float:right;;

}

.Root:hover {
  outline: 2px dashed var(--standard-coal-400);
}

.Root:hover .OverFlowText {
  color: var(--standard-coal-400);
  background-color: rgba(255,255,255,0.8);
}

.OverFlowText {
  transition: 0.3s all;
  color: transparent;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 90px;
  line-height: 30px;
  background-color: transparent;
  font-size: 18px;
  text-align: center;
}
