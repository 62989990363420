
.DeletePanel {
  font-size: 12px;
  font-weight: 500;
  color: var(--standard-color-coal-500);
  padding-inline-start: 10px;
  background-color: #F9F9F9;
  transition: all 0.3s ease-out;
  border-block-start: 1px solid rgb(224, 224, 224);
  line-height: 52px;
  overflow: hidden;
}

.DeletePanel span {
  padding-inline-end: 6px;
}

.IconSize {
  font-size: 1.3rem;
}

.ShowDeletePanel {
  height: 52px;
  min-height: 52px;
  max-height: 52px;
}

.HideDeletePanel {
  height: 0px;
  min-height: 0px;
  max-height: 0px;
  border-block-start: 1px solid transparent;
}

.SmallPanel {

}

.SmallPanel a {
  cursor: pointer;
  text-decoration: none;
  color: var(--standard-jade-500);
}

.SmallPanel a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 740px) {
  .SmallPanel {
    margin-inline: 20px !important;
  }
}



