//.Root {
//  position: absolute;
//  padding-block-start: 2rem;
//  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 4px) !important;
//  bottom: 0px;
//  width: calc(100% - var(--bt-cms-sidebar-move-left));
//  border: 0px;
//  overflow-y: auto;
//  overflow-x: auto;
//  height: calc(100% - (var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 4px));
//  transition: all 0.3s ease-in;
//  background-color: var(--bc-background-color);
//}

.Root {
  position: absolute;
  padding-block-start: 2rem;
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 4px) !important;
  bottom: var(--bt-cms-footer-height);
  width: calc(100% - var(--bt-cms-sidebar-move-left));
  border: 0px;
  overflow-y: auto;
  overflow-x: auto;
  transition: all 0.3s ease-in;
  background-color: var(--bc-background-color);
  margin-left: calc(0px + var(--bt-cms-sidebar-move-left));
  padding-block-end: 2rem;
}


.EditorRoot {
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}


.FormLabel {
  color: var(--standard-coal-400);
  font-size: 14px;
  padding-inline-end: 5px;
}

.TabContainer {
  max-width: 100%;
  margin: auto;
  padding-inline-end: 20px;
  margin-block-end: 20px;
}

.Container {
  max-width: 100%;
  margin: auto;
  padding-inline-end: 20px;
  box-sizing: border-box;

}

.HiddenButton {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.CssInputPanel {
  border: 1px solid var(--standard-coal-200);
  border-radius: 2px;
}

.Icon {
  padding-inline-end: 6px;
}

.SceneEditActionPanel {
  min-width: 500px !important;
  max-width: 500px  !important;
  position: absolute !important;
  right: 10px;
  justify-content: flex-end;
}

.Title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  padding-block-start: 1rem;
}
