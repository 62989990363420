
.BorderPanel {
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  padding-top: 0.5rem;
  position: relative;
  margin-block: 0.0rem;
  margin-inline: 0rem;
  color: darkgray;
  cursor: pointer;
}
