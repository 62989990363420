.Root {
  text-align: center;
  //background-color: var(--bc-background-color);
  padding-block: 3rem;
  padding-inline: 0.5rem;
  box-sizing: border-box;
  //height: calc(100% - 1rem - 2px);

}

.Icon {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  background-color: var(--standard-jade-100);
  width: 30px;
  height: 30px;
  border: 1px solid #A1BFC4;
}

.IconWithParent {
  //border: 3px double #A1BFC4;
}

.Icon:hover {
  box-shadow: 0 2px 6px #00562D20;
}

.Icon span {
  padding-top: 5px;
  font-size: 18px;
  color: var(--standard-jade-500);
}

.Popover {
  margin-block-start: 10px;
}

.CardTitle {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: var(--standard-coal-700);
  padding-inline-start: 10px;
  padding-block: 5px;
}

.CardSubTitle {
  display: inline;
  font-size: 12px;
  font-weight: normal;
  color: var(--standard-coal-700);
  padding-inline-start: 10px;
  padding-block: 5px;
}

.Card {
  width: 300px;
  padding: 10px;
  pointer-events: all;
}

.DragOver {
  border-color: var(--standard-jade-600);
  background-color: var(--standard-jade-200);
}