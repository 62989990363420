.Root {
  display: flex;
  align-items: start;
  padding-block-start: 5px;
  color: var(--standard-coal-300);
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  padding-inline-start: 5px;
  padding-inline-end: 3px;
  overflow: hidden;
  width: 100%;
  margin-inline-start: 5px;
}

.Root:hover .Org1 {
  color: var(--standard-jade-500);
}

.RootWithOutHover {
  display: flex;
  align-items: start;
  padding-block-start: 5px;
  color: var(--standard-coal-300);
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  padding-inline-start: 5px;
  padding-inline-end: 3px;
  overflow: hidden;
  width: 100%;
  margin-inline-start: 5px;
}

.TextField {
  padding: 3px;
  padding-inline: 10px;
  box-sizing: border-box;
  font-size: 8px;
}

.List {
  max-height: 250px;
  overflow-y: auto;
}

.ListItem {
  padding-block:6px;
}

.Org1 {
  width: 45px;
  height: 37px;
  background-color: var(--standard-jade-100);
  border-radius: 50%;
  border: 1px solid var(--standard-jade-200);
  text-transform: uppercase;
  text-align: center;
  line-height: 37px;
  color: inherit;
  color: var(--standard-jade-600);
  font-weight: 600;
  margin-inline-end: 10px;
  margin-block-start: -1px;
}

.Org2 {
  font-size: 16px;
  width: calc(100% - 30px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--standard-coal-500);
  line-height: 35px;
  font-weight: 600;
}

.Edit {
  top: 4px;
  padding-inline-start: 5px;
  position: absolute;
  right: 2px;
  font-size: 20px;
}
