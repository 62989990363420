
.Table {
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 500px) {
  .Table {
    width: 90%;
    margin: auto;
  }
}


