
.LabelSmall {
  color: var(--standard-coal-400);
  font-size: 12px;
  align-items: center;
  padding-block-start: 15px;
  padding-inline-start: 3px;
  display: block;
  //height: 30px;
}

.Label {
  color: var(--standard-coal-400);
  font-size: 14px;
  align-items: center;
  padding-block-start: 10px;
  padding-inline-start: 0px;
  display: block;
}