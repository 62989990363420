
.Root {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
  background: var(--standard-smoke-500);
}

.RootSideBar {
  position: absolute;
  top: 0px;
  width: 260px;
  min-width: 260px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 16px #00000029;
  z-index: 10;
  overflow-y: auto;
  clip: auto;
}

.SideBarTitle {
  font-size: 14px;
  color: var(--standard-coal-500);
  font-weight: bold;
  padding-inline: 32px;
  padding-block-start: 32px;
  padding-block-end: 10px;
}

.SideBarTabPanel {
  padding-inline: 32px;
}

.SideBarSearchPanel {
  padding-inline: 32px;
  padding-block-start: 32px;
  padding-block-end: 16px;
}

.ItemRoot {
  position: absolute;
  left: 260px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: var(--standard-smoke-500);
  padding-inline: 32px;
}

.Tab {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  padding-block: 0px;
  min-width: unset;
  text-align: start;
}

.SideBarList {
  padding-inline-start: 0px;
  list-style: none ;
  overflow: auto;
}

.SideBarTopicItem {
  display: block;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  color: #6F787B;
  font-weight: 600;
  font-size: 14px;
  padding-block: 8px;
}

.SideBarTopicItem hr {
  float: right;
  width: 120px;
  color: darkgrey;
}

.SideBarListItem {
  display: block;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  color: #66737A;
  font-size: 14px;
  padding-block: 8px;
  cursor: pointer;
}

.SelectedItem {
  font-weight: 500;
  color: var(--standard-coal-500);
}



.SideBarCount {
  min-width: 10px;
  text-align: center;
  font-size: 10px;
  color: #4D6069;
  font-size: 12px;
  line-height: 14px;
  padding: 3px;
  background: var(--standard-coal-100);
  border-radius: 3px;
  float: right;
}

.SelectSideBarCount {
  color: var(--standard-jade-500);
  background-color: var(--standard-jade-200);
}

.Button {
  padding-inline-start: 32px;
  float: right;
}

.ItemTitlePanel {
  display: block;
  width: 100%;
  height: 90px;
  max-height: 90px;
  line-height: 80px;
  font-weight: 500;
  font-size: 20px;
  color: var(--standard-coal-500);
}


.ItemsContentPanel {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 90px);
}

.Item {
  //margin-inline: 10px;
  //flex: 1 1;
  box-sizing: border-box;
  padding-inline: 6px;
  padding-block: 20px;
  //max-width: 100%;
}

.ItemSmall {
  margin-inline: 10px;

  //flex: 1 1;
  box-sizing: border-box;
  padding: 5px;
}


.DefaultPanel {
  padding: 32px;
}

.LinkPanel {
  padding-inline: 32px;
  font-size: 14px;
  color: var(--standard-coal-400);
  cursor: pointer;
}

.LinkPanel span {
  font-size: 14px;
}


.TitlePanel {
  padding-inline: 32px;
  padding-block-start: 16px;
  padding-block-end: 32px;
}

.Title {
  font-size: 28px;
  font-weight: bold;
  color: var(--standard-coal-500);
}

.SubTitle {
  font-size: 12px;
  padding-block-start: 10px;
  padding-inline-start: 5px;
  color: var(--standard-coal-300);
}

.SecondSubTitle {
  font-size: 14px;
  padding-block-start: 10px;
  padding-inline-start: 5px;
  color: var(--standard-coal-300);
  text-transform: uppercase;
}
.SubTitleCount {
  float: right;
  font-size: 12px;
  color: var(--standard-coal-300);
}

.SecondContainer {
  max-width: 2000px;
  margin: auto;
  display: flex;
  padding:32px;
  height: 450px;
  gap: 5px;
  background-color: var(--standard-smoke-500);
}

.SecondContainerAction {
  display: flex;
  flex-direction: row-reverse;
  padding: 22px;
  padding-block-start: 30px;
  padding-inline: 32px;
  background-color: var(--standard-smoke-500);
  //padding-block-start: 60px;
}

.SecondSubTitleCount {
  float: right;
  font-size: 16px;
  color: var(--standard-coal-300);
  color: #66737A;
}

.SecondContainerPreview {
  display: inline-block;
  width: 50%;
  max-width: 674px;
  height: 450px;
}

.SecondContainerPreview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.SecondContainerTemplatePreview {
  padding-inline-start: 32px;
  background-color: var(--standard-smoke-500);
}

.SecondContainerSelection {
  display: inline-block;
  width: calc(100% - min(50%, 400px));
  height: 100%;
  overflow-y: auto ;
  overflow-x: hidden;
}

.Selection {
  display: flex;
  flex-wrap: wrap;
}

.NewLabelPanel {
  padding-inline-start: 8px;
  padding-block: 8px;
  max-width: 320px;

}
.NewLabelTitle {
  color: var(--standard-coal-400);
  font-size: 12px;
  align-items: center;
  display: block;
  padding-block-start: 5px;
}
.nextArrowBtn {
  background-color: var(--standard-jade-500);
}
