
.Root {
  padding-inline-start: 0.0rem;
  padding-inline-end: 0.0rem;
  box-sizing: border-box;
}

.Root:focus {
  outline: none;
}


.ItemsContentPanel {
  box-sizing: border-box;
}

.Dialog {
  width: 80%;
  height: 80%;
}

.TextEditor {
  width: 100%;
  height: 100%;
}

.CloseIcon {
  float: right;
}

@media screen and (max-width: 500px){
  .Dialog {
    width: 90%;
    height: 90%;
  }
}