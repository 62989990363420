.Root {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 4rem;
}

.Root  * {
  flex: 1 1;
}

.Root:focus {
  outline: none;
}

.RootNormal {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 4rem;
}

.RootNormal  * {

}
