.DialogContent {
  padding: 16px;
}

.FormLabel {
  display: block;
  color: var(--standard-coal-400);
  font-size: 14px;
  padding-inline-end: 5px;
  padding-block-start: 5px;
}

.ButtonInlineSpace {
  margin-inline-start: 1rem !important;
}

.Button {
  padding-inline-start: 1.3rem;
  padding-inline-end: 1.3rem;
}

.Button:disabled {
  color: white !important;
}

.CloseIcon {
  float: right;
}

