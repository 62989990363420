
.Container {
    padding: 0px !important;
    max-width: 600px;
    margin: auto;
}

.SummaryPanel {
    padding-inline: 5%;
    padding-block: 5%;
}


.SummaryTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 2rem;
}

.EnvironmentPanel {
    padding-inline: 5%;
    padding-block: 5%;
}

.EnvironmentSubPanel {
    padding-block-start: 2rem;
    text-align: center;
}

.EnvironmentButton {
    height: 5rem;
    width: 8rem;
    border-width: 1px;
    border-style: solid !important;
}

.EnvironmentButton:hover {
    height: 5rem;
    width: 8rem;
}

.EnvironmentButtonSelected {
    height: 5rem;
    width: 8rem;
    background-color: var(--bt-cms-color-primary) !important;
    color: white !important;

}

.VersionPanel {
    padding-inline: 5%;
    padding-block: 5%;
}

.VersionList {
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-block-start: 1rem;
}

.NotePanel {
    padding-block-start: 15px;
}

.NotePanel textarea {
    padding-inline-start: 10px;
}

.VersionListItemButton > div {
    pointer-events: none;
}

.VersionListItemButton > span {
    pointer-events: none;
}

.VersionItemSelected {
    background-color: lightgrey;
}

.VersionItemDeployed {
    color: var(--bt-cms-color-primary);
}


.VersionItemDeployed div {
    color: var(--bt-cms-color-primary);
}

.VersionTitle {
    font-size: 14px;
}

.VersionTitle b {
    text-transform: uppercase;
}


@media screen and (max-width: 400px) {
    .Root {
        width: 90%;
    }

    .VersionPanel {
        padding: 0px;
    }

    .EnvironmentPanel {
        padding: 0px;
    }

    .SummaryPanel {
        padding: 0px;
    }
}

.ActionPanel {
    display: flex;
    position: relative;
    height: 60px;
}

.BackButton {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    font-size: 16px;
}

.NextButton {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    font-size: 16px;
}