.Root {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 4rem;
}

.Root  * {
  box-sizing: border-box;
}

.CardPanel {
  clip: auto;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
}

.CardPanel * {
  box-sizing: border-box;
}

.CardImage {
  object-fit: contain;
}

.CardTitle {
  box-sizing: border-box;
}
.CardDesc {
  box-sizing: border-box;
}


.NextButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  width: 50px;
  right: 0;
  top: 20%;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
}

.NextButton:hover {
  opacity: 1;
}

.BackButton {
  position: absolute;
  background-color: transparent;
  opacity: 0.2;
  width: 50px;
  top: 20%;
  left: 0;
  cursor: pointer;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2));
}

.BackButton:hover {
  opacity: 1;
}


.AnchorPanel {
  position: relative;
}
