.Container {
  clip: auto;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}

.Container * {
  box-sizing: border-box;
}

.Image {
}