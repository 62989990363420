
.Root {
  padding-inline-start: 0.0rem;
  padding-inline-end: 0.0rem;
  box-sizing: border-box;
}

.Root:focus {
  outline: none;
}


.ItemsContentPanel {
  box-sizing: border-box;
}