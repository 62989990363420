.Root {
  padding-inline: 5px;
  font-size: 12px;
  color: var(--standard-coal-400);
  padding-block-end: 10px;
}

.ToggleButton {
  text-transform: none;
  padding-block: 4px;
}

.GridContainer {
  padding-block-start: 10px;
  align-items: center;
}

.Divider {
  padding-block: 10px;
}

.TargetLabel {
  font-size: 12px;
  padding-inline: 10px;
  line-height: 30px;
  padding-block-start: 3px;
  height: 40px;
  vertical-align: middle;
}

.TargetInput {
  padding-block-start: 10px;
}