.Root {
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid var(--standard-jade-600);
  width: 70%;
  height: 70%;
}

.Bar {
  display: inline-block;
  height: 100%;
  border-inline-end: 1px solid var(--standard-jade-600);
}

.NoBar {
  display: inline-block;
  height: 100%;
}
