
.Root {
  width: 80%;
  margin: auto;
  position: relative;
}


.Table {
  width: 80%;
  margin: auto;
}

.DisplayList {
  float: right;
  margin-inline-end: 10%;
}

.UploadPanelInGrid {
  position: absolute;
  right: 3px;
  top: 5px;
  z-index: 100;
}

.TemplateRoot {
  padding-block: 30px;
}

.TemplateDialog {

}

.TemplateDialog > div > div {
  max-width: unset;
  box-sizing: border-box;
  height: 90%;
  max-height: 900px;
}

@media screen and (max-width: 500px) {
  .Table {
    width: 90%;
    margin: auto;
  }
}


