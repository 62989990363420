.Root {
  list-style: none;
  font-size: 0.9rem;
  width: 100%;
}

.FileName {
  display: inline-block;
  width: 55%;
  white-space: nowrap;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FileNameFull {
  width: 100%;
}

.RenameIcon {
  font-size: 1rem;
}

.FileName a {
  text-decoration: none;
}

.FileName a:hover {
  text-decoration: underline;
}

.FontSize {
  font-size: 0.9rem;
}

.Size {
  display: inline-block;
  width: 15%;
  text-align: center;
  white-space: nowrap;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
}


.Date {
  display: inline-block;
  width: 15%;
  text-align: center;
  white-space: nowrap;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Action {
  display: inline-block;
  width: 15%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: none;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Icon {
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
}