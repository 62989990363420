.Root {
  display: inline-block;
  position: relative;
  width: 65px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid var(--standard-smoke-700);
  background-color: var(--standard-smoke-500);
  cursor: pointer;
}

.Indicator {
  position: absolute;
  display: block;
  top: 3px;
  left: 5px;
  width: 22px;
  height: 22px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid var(--standard-smoke-700);
  pointer-events: none;
  transition: left 0.3s;
}

.Indicator span {
  display: none;
}

.IndicatorActive {
  left: 35px;
  border: 2px solid var(--standard-jade-500);
  background-color: var(--standard-jade-500);
}

.IndicatorActive span {
  display: block;
  color: white;
  font-size: 18px;
  text-align: center;
  width: 100%;
  line-height: 21px;
}

.OnLabel {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  left: 8px;
  top: 8px;
  font-size: 14px;
  user-select: none;
  pointer-events: none;
  transition: opacity 0.3s;
}

.OffLabel {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  left: 34px;
  top: 8px;
  font-size: 14px;
  user-select: none;
  transition: left 0.3s;
  pointer-events: none;
  transition: opacity 0.3s;
}

.HideLabel {
  opacity: 0;
}