.TextField {
  width: 150px;
  height: 20px;
  color: var(--standard-coal-300);
}

.TextField input {
  padding-block: 6px;
  font-size: 14px;
  padding-inline: 5px;
}


