
.PermissionPanel {
  margin: auto;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 400px;
}


.PermissionMessage {
  padding-block-start: 20px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  font-color: var(--standard-555555);

}

.PermissionIcon {;
  padding-block-start: 100px;
  font-color: var(--standard-555555);
}
.PermissionIcon > span {
  font-size: 100px;
  font-color: var(--standard-555555);
  text-align: center;
}

@media screen and (max-width: 400px ){
  .PermissionPanel {
    padding-inline: 40px;
  }
  .PermissionIcon {
    padding-block-start: 30px;
  }
}
