.Root {
  box-sizing: border-box;
  border-radius: 6px;
  width: 70%;
  height: 70%;
  text-align: center;
  padding-block-start: 8px;
}

.Root span {
  color: var(--standard-jade-600);
  text-align: center;
  justify-content: center;
}

