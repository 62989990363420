.DialogContent {
}

@media screen  and (max-width: 400px) {
  .DialogContent {
    padding: 5px;
  }
}

.CloseIcon {
  float: right;
}
