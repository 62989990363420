.Container {
  margin: auto;
  box-sizing: border-box;
}

.GridContainer {
  box-sizing: border-box;
  margin: auto;
  max-width: 80%;
}

.Title , .Title2 {
  letter-spacing: 0px;
  color: var(--standard-black);
  font: normal normal 600 24px/56px Inter;
  padding-inline-start: 15px;
  line-height: 28px;
  padding-block-end: 10px;
}

.Title2 {
  display: none;
}



.SubTitle {
  letter-spacing: 0px;
  color: var(--standard-black-55555);
  font: normal normal normal 14px/21px Inter;
  padding-block-end: 20px;
  padding-inline-start: 15px;
}

.GridItem {
  padding: 10px !important;
  box-sizing: border-box;
}

.GridItemContainer {
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 700px){

  .GridItem {
    padding: 0px
  }

  .GridItemContainer {
    padding: 0px;
  }

  .Title {
    display: none;
  }

  .Title2 {
    display: unset;
  }

  .SubTitle {
    font: normal normal normal 14px/21px Inter;
    padding-block-end: 20px;
    padding-inline-start: 15px;
  }
}

.Icon {
  padding-inline-end: 0.5rem;
}
