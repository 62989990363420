
.SceneActionPanel {
  position: absolute;
  right: 5px;
  margin: auto;
}

.SceneEditActionPanel {
  display: none;
  min-width: 600px !important;
  max-width: 600px  !important;
  position: absolute !important;
  right: 10px;
  justify-content: flex-end;
}

.SceneMenu {
  display: none !important;
  position: absolute !important;
  right: 0px;
}


//@media screen and (max-width: 700px) {
//  .SceneMenu {
//    display: block !important;
//  }
//}

//@media screen and (max-width: 720px) {
//  .SceneEditActionPanel {
//    display: none !important;
//    min-width: 0px !important;
//    max-width: 0px !important;
//  }
//}

.ScreenButton {
  padding-inline: 0px !important;
}

.IconCenter {
  margin-left: 0px;
  margin-right: 0px;
  color: var(--standard-jade-600);
  padding-block: 0px;
  padding-inline: 6px;
}

.IconCenter:hover {
  background-color: transparent;
}

.IconCenter:disabled {
  color: var(--standard-jade-300);
}

.Icon {
  margin-left: 0px;
  margin-right: 10px;
  color: var(--standard-jade-400);
}

.DeviceIcon {
  margin-left: 0px;
  margin-right: 0px;
}

.DefaultViewMenuItem {
  color: var(--bt-cms-color-primary) !important;
}