
.RootActionPanel {
  box-sizing: border-box;
  position: absolute;
  right: 3px;
  top: 3px;
  width: auto;
  z-index: 200;
  //clip: auto;
  //overflow: hidden;
}

.RootActionMenu {
  display: flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  text-align: right;
  height: 2.5rem;
  max-height: 2.5rem;
  z-index: 200;
  padding-inline: 0.4rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px #00562D20;
  border-inline: 1px solid lightgray;
  border-block-end:  1px solid lightgray;
  background-color: white;
  overflow: hidden;
  clip: auto;
  opacity: 1;
  //transition: all 0.3s ;
  //transform: translateX(173px);
}


//.RootActionPanel:hover .RootActionIcon {
//  display: none;
//}
//
//.RootActionPanel .RootActionMenu {
//  visibility: hidden;
//}
//
//.RootActionPanel:hover .RootActionMenu {
//  visibility: visible;
//}


.RootActionPanelWithParent {
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: 3px;
  width: auto;
  z-index: 200;
  clip: auto;
  overflow: hidden;
  min-height: 40px;
}


.ActionPanel {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  text-align: right;
  height: 2.5rem;
  max-height: 2.5rem;
  z-index: 200;
  padding-inline: 0.4rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px #00562D20;
  border-inline: 1px solid lightgray;
  border-block-end:  1px solid lightgray;
  background-color: white;
  //clip: auto;
  //overflow: hidden;
  clip: auto;
  opacity: 1;
  //transition: all 0.3s ;
  //transform: translateX(143px);
}

.SectionAction {
  background-color: var(--standard-jade-100);
}

.CircleMenu {
  border-radius: 50%;
  padding-inline: 2px;
}
//.RootActionPanel:hover .RootActionMenu {
//  width: 190px;
//  transform: translateX(0px);
//}


.ActionPanelWithParent {
  //transform: translateX(-147px);
  background-color: var(--standard-jade-100);
}

.RootActionPanelWithParent:hover .ActionPanelWithParent {
  //width: 140px;
  z-index: 200;
  //transform: translateX(-4px);
}

.RootActionPanelWidget {
  position: absolute;
  right: 3px;
  width: auto;
  z-index: 200;
  top: unset;
  bottom: 3px;
  //clip: auto;
  //overflow: hidden;
}

//.RootActionPanelWidget:hover .ActionPanel {
//  width: 140px;
//  transform: translateX(0px);
//}



.ActionPanelWidget {
  //top: calc(50% - 1.25rem);
  //top: unset;
  //bottom: 5px;
  //left: calc(50% - 25px);
  //right: calc(50% + 25px);
  //right: 5px;
  border-start-end-radius: 0.5rem;
  border-start-start-radius: 0.5rem;
}



.WidgetAction {
  //background-color: lightyellow;
  background-color: var(--standard-jade-100);
}

.Icon {
  padding: 3px  5px !important;
  padding-top: 6px !important;
  color: var(--standard-jade-500) !important;
  background-color: transparent;
}

.Icon:hover {
  background-color: transparent;
}

.IconSmall {
  padding: 3px  5px !important;
  padding-top: 6px !important;
  color: var(--standard-jade-500) !important;
  background-color: transparent;
}

.IconFlipVertical {
  transform: scaleY(-1);
  margin-block-start: 4px;
}


.IconSmall:hover {
  background-color: transparent;
}

.SectionLabel {
  position: absolute;
  top: 5px;
  left: 3px;
  width: 25px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  display: none;
  color: var(--standard-jade-500);
  display: none;
}

.SectionLabelWithParent {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 25px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: var(--standard-jade-500);
  display: none;
}

.SectionLabelInvisible {
  display: inline-block;
  font-size: 5px;
  min-width: 10px;
  font-weight: bold;
  color: transparent;
}