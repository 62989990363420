.Root {
  box-sizing: border-box;
  display: grid;
  min-height: 1rem;
  //width: 100%;
  //overflow: hidden;
  //clip: auto;
}


.Root:focus {
  outline: none;
}

.Col {
  //overflow: hidden;
  //clip: auto;
}