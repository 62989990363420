.LeftPanel {
  position: relative;
  right: 0rem;
  margin-right: 1rem;
}

.ContentContainer {
  position: absolute;
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height));
  bottom: var(--bt-cms-footer-height);
  left: var(--bt-cms-sidebar-move-left);
  right: 0px;
  width: calc(100% - var(--bt-cms-sidebar-move-left));
  padding-top: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease-in;
}

.ContentContainerOverflowX {
  overflow-x: auto;
}

.Icon {
  color: inherit;
  margin: auto;
  font-size: 24px;
  padding-inline-end: 8px;
}

.DisableEventPanel {
  pointer-events: none;
}

