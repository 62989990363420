
.PIMSearchPanel {
  display: block;
  width: 100%;
  margin: auto;
}

.PIMSearchDetail {
  width: var(--bt-cms-search-panel-width);
  margin: auto;
}

.PIMAvailableField {
  padding-block-start: 10px;
}
.PIMSearchOption {
  box-sizing: border-box;
  display: inline-block;
  width: 200px;
  vertical-align: top;
  padding-inline-start: 10px;
  padding-inline-end: 3px;
  padding-block-start: 10px;

}

.PIMSearchResult {
  display: inline-block;
  box-sizing: border-box;
  width: calc(100% - 200px);
  padding: 5px;
  padding-block-start: 10px;
  overflow: auto;
  //min-height: calc(100% - 90px);
}

.PIMSearchResultOverflow {
  overflow: auto;
}

.PIMSearchNotFound {
  background-color: #ffe8ef;
  height: 25px;
  border-inline: solid 2px darkred;
  padding-inline-start: 10px;
  margin-inline-end: 15px;
  padding-block   : 10px;
  vertical-align: middle;
  border-radius: 5px;
  color: darkred;
}

.PIMSearchNotFoundLabel{
  display: inline-block;
  vertical-align: middle;
  margin-block-start: -15px;
  padding-inline-start: 10px;
}

.PIMSearchScrollPanel {
  position: fixed;
  display: block;
  bottom: 50px;
  width: calc(100% - 120px);
  height: 20px;
  overflow-x: scroll;
}

.PIMFieldPanel {
  list-style: none;
  padding-left: 0px;
  font-size: 12px;
}

.PIMTable {
  font-size: 12px;
  border-spacing: 0px;
  max-width: 100%;width: 100%;

}

.PIMTableOverflow {
  min-width: 100%;
  max-width: unset;
}


.PIMTable tr {
  margin: 0px;
  padding: 0px;
  border: none;
}

.PIMTable tr th {
  text-align: start;
  padding-block: 8px;
  padding-inline: 6px;
  color: black;
}

.HeaderIcon:nth-child(1) {
  padding-inline-start: 5px;
}

.AddPanel {
  padding-block: 3px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-inline-end: 20px;
  padding-block: 4px;
}


.AddPanel:hover {
  text-decoration: underline;
}

.AddIconPanel {
  position: absolute;
  right: 2px;
}

.AddIcon {
  font-size: 16px;
  font-width: 400;
  color: var(--bt-cms-color-drawer);
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0;
}

.AddIcon:hover {
  color: var(--bt-cms-color-info);
}

.HeaderIcon {
  font-size: 12px;
  font-width: 400;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0;
}

.PIMTable tr td {
  padding-inline-start: 2px;
  padding: 8px;
  vertical-align: top;
  border: none;
  border-top: 1px solid #D3DAE6;
}

.PIMTable tr th > span {
 white-space: nowrap;
}

.PIMLoadMore {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.PIMDetail {
  border-spacing: 0px;
  width: 90%;
  padding-inline-start: 2%;
}

.DetailIcon {
  font-size: 16px;
  font-width: 400;
  padding-inline-end: 5px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  opacity: 0;
}

.DetailIconDisable {
  font-size: 16px;
  font-width: 400;
  padding-inline-end: 5px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  text-decoration: line-through;
  color: lightgrey;

}

.DetailIcon:hover {
  color: var(--bt-cms-color-primary);
  transform: scale(1.1,1.1);
}

.PIMDetail tr td > span{
  white-space: nowrap;
}

.PIMDetail tr td {
  border: 0px solid;
}

.PIMDetail tr td:nth-child(1) {
  font-weight: 700;
}

.PIMSearchFieldTooManyMessage {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 900px){

  .AddPanel {
    padding-inline: 20px;
    line-height: 20px;

    .AddIconPanel {
      right: 22px;
    }
  }

  .PIMSearchOption {
    display: block;
    width: 100%;
  }

  .PIMSearchResult {
    display: block;
    width: 100%;
    min-height: calc(100% - 125px);
  }

  .PIMSearchNotFound {
    margin-inline-end: 0px;
  }
}


.FacetPanel {
  padding: 5px;
  font-size: 13px;
  width: 250px;
  padding-block-end: 20px;
}

.FacetSubTitle {
  font-size: 14px;
  text-align: right;
}
.FacetTitle {
  line-height: 2rem;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FacetLabel1 {
  padding-block-start: 5px;
  display: inline-block;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

.FacetLabel2 {
  padding-block-start: 5px;
  display: inline-block;
  width: 15%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: end;
}

.FacetProgress {
  display: block;
}

.FacetIcon {
  font-size: 12px;
  color: gray;
  padding-inline-end: 3px;
  cursor: pointer;
}