.Space {
  justify-content: center;
  vertical-align: bottom;
  align-items: end;
  padding-block-start: 80px;
}

.Root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bc-background-color);
  //height: calc(100% - 1rem - 2px);
  max-width: 646px;
  width: 90%;
  height: 236px;
  margin: auto;
  border: 1px dashed #B7BFC3;
}

.Title {
  padding-block:  10px;
  font-size: 20px;
  color: var(--standard-coal-500);
  font-weight: 600;
}

.Icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  background-color: var(--standard-jade-100);
  width: 40px;
  height: 40px;
  border: 1px solid #A1BFC4;
}

.Icon:hover {
  box-shadow: 0 2px 6px #00562D20;
}

.Icon span {
  padding-top: 8px;
  color: var(--standard-jade-500);
}

.Popover {
  margin-block-start: 10px;
}

.Card {
  width: 300px;
  padding: 10px;
  pointer-events: all;
}

.CardTitle {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: var(--standard-coal-700);
  padding-inline-start: 10px;
  padding-block: 5px;
}

.Container {
  background-color: var(--bc-primary-color) !important;
  display: inline-flex;
  background-color: lightgray;
  min-height: 3rem;
  min-width: 4rem;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #c3f3c7;
  transition: all 0.3s  ease-in;
  cursor: pointer;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  pointer-events: all;
}

.Container:hover {
  background-color: var(--bc-primary-color-darker) !important;
  color: white;
  box-sizing: border-box;
}

.ContainerSection {
  display: inline-flex;
  background-color: var(--bc-secondary-color) !important;
  min-height: 3rem;
  min-width: 3rem;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: gray;
  transition: all 0.3s  ease-in;
  cursor: pointer;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
}

.ContainerSection:hover {
  background-color: var(--bc-secondary-color-darker) !important;
  color: white;
}
//
//.Icon {
//  color: inherit;
//  margin: auto;
//  font-size: 3rem;
//  transition: all 0.3s  ease-in;
//}
