
.Root {
  padding-inline-start: 0.0rem;
  padding-inline-end: 0.0rem;
  min-height: 3rem;
  max-height: 3rem;
  box-sizing: border-box;
  color: lightgray;
  text-transform: uppercase;
}

.Root  span {
  display: block;
  text-align: center;
  vertical-align: middle;
  line-height: 3rem;
  min-height: 3rem;
  min-height: 3rem;
}

.Root:focus {
  outline: none;
}
