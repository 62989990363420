.Root {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  min-height: 150px;
}

.Level1 {
  display: block;
  padding: 0;
  list-style-type: none;
  clip: auto;
  transition: max-height 0.3s linear ;
  overflow: hidden;
}

.Level2 {
  display: block;
  list-style: none;
}

.BreadCrumb {
  display: block;
  list-style: none;
  padding: 0px;
}

.BreadCrumb li {
  display: inline-block;
}
