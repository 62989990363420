.Root {
  display: block;
  box-sizing: border-box;
}

.Title {
  font-weight: 600;
  font-size: 16px;
}

.Item {
  font-size: 14px;
  padding: initial;
  margin: initial;
}

.Tab {
  overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.Tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.Tab button:hover {
  background-color: #ddd;
}

.Tab button.active {
  background-color: #ccc;
}


.TabContent {
  padding: 6px 12px;
  border-top: none;
}