.FloatBar {
  position: fixed;
  //height: var(--bt-cms-appbar-action-height);
  //min-height: var(--bt-cms-appbar-action-height);
  height: 40px;
  min-height: 40px;
  color: black;
  z-index: var(--bt-cms-appbar-action-z-index);
  //top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) ) !important;
  //left: calc(var(--bt-cms-sidebar-move-left));
  bottom: 32px;
  right: 32px;
  background-color: var(--standard-jade-100);
  border-radius: 6px;
  //right: 10px;
  //width: calc(100% - var(--bt-cms-sidebar-move-left));
  width: 300px;
  transition: all 0.3s ease-in;
  overflow: hidden;
  box-sizing: content-box;
  box-shadow: 0px 2px 8px #00562D3D;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Toolbar {
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  height: var(--bt-cms-appbar-action-height) !important;
  min-height:var(--bt-cms-appbar-action-height) !important;
}


.Breadcrumbs {
  min-width: 300px;
}


.BreadcrumbSeparator {
  font-size: 16px;
  line-height: 18px;
}

.BreadcrumbLabel {
  font-size: 1rem;
}

.BreadcrumbLabelSmall {
  font-size: 12px;
}

@media screen and (max-width: 900px){
  .Toolbar {
    width: 100%;
  }
}

@media screen and (max-width: 450px){
  .Breadcrumbs {
    display: none;
  }
}




