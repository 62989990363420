.Root {
  width: 100%;
  height: 250px;
  width: 100%;
}

.Root > div {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: white;
  color: seagreen;
}

.Root.cm-editor.cm-scroller.cm-content {
  white-space: unset !important;
}

.cm-content {
  white-space: unset !important;
}
