.Container {
  text-align: center;
  width: 100%;
  text-transform: unset !important;
  border-radius: 0;
  height: 72px;
  padding: 0px;
  color: #92a6a8;
  background-color: transparent;
}

.DrawerPointer {
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);

  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: inline-block;
  z-index: 300000;
  width: 16px;
  height: 16px;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  position: absolute;
  top: 25px;
  right: -8px;
  z-index:  calc(var(--bt-cms-drawer-z-index) + 1);
}



.ContainerSelected {
  text-align: center;
  width: 100%;
  text-transform: unset !important;
  border-radius: 0;
  height: 72px;
  padding: 0px;
  background-color: var(--bt-cms-color-sidebar-active-dark) !important;
}

.Container.active {
  background-color: var(--bt-cms-color-sidebar-active-dark) !important;
}

.ItemsContentPanel {
  pointer-events: none;
  width: 100%;
}

.Title {
  font-size: 12px;
  line-height: 0.5rem;
}

.Icon {
  display: block;
  text-align: center;
  width: 100%;
  padding-block-end: 10px;
}