.Root {
  min-height: 120px;
  padding-block-end: 30px;
  min-width: 400px;
}

.BreadCrumb {
  display: block;
  list-style: none;
  padding: 0px;
}

.NarrowPanel {
  display: inline-block;
  vertical-align: middle;
  padding-inline-end: 5px;
}

.NarrowPanelFill {
  display: block;
  padding-inline-end: 5px;
}


.SortByPanel {
  display: block;
  vertical-align: middle;
  padding-inline-end: 5px;
  padding-block-start: 5px;
}


.RefinementSection {
  display: inline-block;
  padding-inline-end: 5px;
}

.PaginationPanel {

}

.PaginationPanel div:nth-child(1) {
  padding-inline-start: 10px;
  padding-inline-end: 20px;
}

.PaginationPanel div:nth-child(2) {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.PaginationPanel div:nth-child(4) {
  display: block;
  float: right;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  text-align: end;
}

.PaginationSection {
  display: inline-block;
  padding-inline-end: 5px;
}

.PaginationSectionFill {
  display: block;
  text-align: center;

}

.PaginationSectionHidden {
  display: none;
}

.PaginationButton {
  padding: 0px 4px;
  background-color: white;
  border: 1px solid lightgrey;
  cursor: pointer;
}

.PaginationIndex {
  padding-inline-end: 7px;
}

.Active {
  background-color: #efefef;
}

.TextInput {
  font-size: inherit;
  height: 24px;
}

.SelectInput {
  font-size: inherit;
  padding-inline: 5px;
  padding-block: 5px;
}

.BreadCrumb li {
  display: inline-block;
}


.QueryPanel {
  display: inline-block;
  vertical-align: top;
  width: calc(80% - 20px);
  padding-inline-start : 20px;
}

.QueryPanelFull {
  display: block;
  width: 100%;
  padding-inline-start : 0px;
}

.FacetListPanel {
  margin-block-start: 20px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 20%;
  background-color: #ebeef4;
}

.FacetListPanel h3 {
  padding-inline: 10px;
}

.FacetListFullPanel {
  width: 100%;
}


.FacetFilterPanel {
  background-color: #f3f4f7;
  padding: 0.5em 0.75em;
  display: block;
}

.FacetFilterPanel > a:first-child {
  float: right;
}

.FacetFilterPanel h4 {
  text-transform: uppercase;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
}

.FacetFilterPanel > a {
  font-size: 12px;
  color: darkred;
  padding: 3px 0 3px 1.25em;
  text-indent: -0.6em;
  line-height: 14px;
  font-weight: 700;
  display: block;
  cursor: pointer;
  padding-block: 8px;
}


.FacetFilterPanel  > a > span:last-child {
  font-weight: normal;
}


.FacetPanel {
  list-style: none;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 0px;
}

.FacetPanel > li > a {
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding-inline: 10px;
  padding-block: 5px;
}


.FacetPanel > li > a:before {
  content: "\00b1";
  padding-inline-end: 5px;
}

.FacetPanel > li > a:after {
  content: "\25BC";
  padding-inline-end: 5px;
  font-size: 10px;
  display: inline-block;
  float: right;
}

.FacetPanel > li > a:hover , .FacetPanelActive {
  background-color: #254287;
  color: white;
  font-weight: 400;
}


.FacetPanel > li > a:active:after,
.FacetPanel > li > .FacetPanelActive:after {
  content: "\25B2";
}

.FacetPanel > li > div > ul > li {
  padding-block-start: 5px;
}
.FacetPanel > li > div > ul > li > i {
  display: inline-block;
  padding-inline-start: 15px;
  vertical-align: top;
  margin-top: 2px;
}

.FacetPanel > li > div > ul > li > a,
.FacetPanel > li > div > ul > li{
  color: #666;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 35px);
  text-decoration: none;
}

.FacetPanel > li > div > ul > li > a:hover,
.FacetPanel > li > div > ul > li:hover {
  color: #296ebb;
}


.FacetListPanelTop {
  display: block;
  width: 100%;
}


.ProductListPanel {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 150px;
  gap: 20px;
  padding-bottom: 30px;
}

.ProductPanel {
  clip: auto;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  min-height: 400px;
  min-width: 250px;
  border-bottom: 1px solid lightgrey;
}

.ProductImagePanel {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProductInfoPanel {
  padding: 10px 0;
}

.ProductImage {
  flex: 0 0;
  object-fit: contain;
}

.ProductQty {
  display: inline-block;
  float: left;
  font-size: 10px;
  padding: 10px 0px;
}

.ProductPrice {
  display: inline-block;
  float: right;
  font-size: 10px;
}

.ProductCompare {
  display: block;
  clear: both;
  padding: 10px 0px;
}

.ProductStar {

}

.FacetPanel {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.FacetButtonPanel {
  text-align: center;
  padding-block-start: 5px;
  padding-block-end: 5px;
  font-size: 14px;
}

.FacetPanelSmall {
  text-align: center;
  cursor: pointer;
  font-size:20px;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

.FacetItem {
  display: block;
}

.AccordionClosed {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}


.ListProductPanel {
  clip: auto;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
}

.ListProductImagePanel {
  display: table-cell;
  max-height: 70px;
  padding-inline-start: 100px;
  padding-inline-end: 50px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}


.ListProductInfoPanel {
  display: table-cell;
  padding-block-start: 10px;
  max-height: 70px;
  max-width: 300px;
}

.ListProductImage {
  flex: 0 0;
  object-fit: contain;
}

.ListProductQty {
  display: inline-block;
  float: left;
  margin-block-start: -60px;
  font-size: 12px;
}

.ListProductPrice {
  display: inline-block;
  float: right;
  font-size: 12px;
  margin-block-start: -90px;
}

.ListProductCompare {
  display: inline-block;
  clear: both;
  margin-block-start: -10px;
  padding: 10px 0px;
}


.EmptyPanel {
  min-height: 300px;
  font-color: lightgray;
  font-weight: 700;
  font-size: 14px;
}


