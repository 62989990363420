.Container {
  display: flex;
  min-height: 4rem;
  min-width: 4rem;
  justify-content: center;
  align-items: center;
}

.EmptyLabel {
  display: inline;
  font-size: 2rem;
  color: lightgray;
  height: 2rem;
}