.Panel {
    display: flex;
    min-width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-block: 20px;
}

.LoginPanel {
    flex: 0 0;
    margin: auto;
}

.LoginPanel fieldset {
    padding-block: 3px;
    border: none;
    min-width: 300px;
}

.LoginLabel {
    min-width: 85px;
    font-weight: bold;
    display: inline-block;
    text-align: end;
    padding-inline-end: 5px;
}

.LoginInput {
    height: 24px;
    max-width: calc(100% - 130px);
    padding-inline: 8px;
    display: inline-block;
}

.LoginPanel fieldset a {
    font-weight: bold;
    padding-inline-start: 5px;
    cursor: pointer;
    font-style: italic;
    text-decoration: none;
}

.LoginPanel fieldset a:hover {
    color: darkgrey;
}

.ForgetPanel {
    flex: 0 0;
    margin: auto;
    display: inline-block;
    width: fit-content;
    padding-block-start: 10px;
}

.ForgetPanel span {
    display: block;
}

.ForgetPanel a {

}

.ErrorPanel {
    flex: 0 0;
    margin: auto;
    display: inline-block;
    width: fit-content;
    padding-block-end: 20px;
}