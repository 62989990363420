$default-margin-block-start: 40px;

.Root {
  position: relative;
  height: 150px;
  width: 90%;
  margin: auto;
  max-width: 700px;
}


.TopMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.SearchMenu {
  width: 50%;
  min-width: 300px;
}

.DeleteMenu {
  flex: 0 0 ;
  width: 50px;
  padding-inline-start: 10px;
}

.MasterPanel {
  margin-block-start:  $default-margin-block-start;
  padding: 10px;
}

.ActionPanel {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 2rem;
}

.ActionPanel {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 10px;
}

.InputPanel {
  padding: 10px;
  width: calc(100% - 20px);
}

.DetailPanel {
  display: block;
  position: relative;
  padding: 10px;
  width: calc(100%);
  max-width: calc(100%);
  margin-block-start:  $default-margin-block-start;
}

.ItemsPanel {
  position: absolute;
  width: calc(100% - 20px);
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  left: 0px;
  transition: all 0.23s ease-in;

}

.ItemsPanelOut {
  left: calc(110% * -1);
  opacity: 0;
}

.EmptyPanel {
  text-align: center;
  line-height: 50px;
  height: 50px;
}

.EmptyAddIcon {
  vertical-align: sub;
}

.ContentPanel {
  position: absolute;
  left: 100%;
  width: calc(100% - 20px);
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  transition: all 0.3s ease-in;
  opacity: 0;
}


.ContentPanelIn {
  left: 0px;
  opacity: 1;
}

.ContentActionPanel {
  position: relative;
}

.ContentActionTitle {
  position: absolute;
  text-align: center;
  line-height: 2.5rem;
  left: 40px;
  right: 10px;
  bottom: 0;
  top: 0;
  overflow: hidden;
  text-wrap: none;
  text-overflow: ellipsis;
}


@media screen and (max-width: 650px) {
  .SearchMenu {
    width: calc(100% - 50px);
  }

  .DeleteMenu {
    width: 50px;
  }
}

.GroupInfoPanel {

}
