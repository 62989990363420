@import "src/config/Common.module";

.AppBar {
  background-color: var(--bt-cms-color-primary);
  height: var(--bt-cms-appbar-user-height) !important;
  min-height: var(--bt-cms-appbar-user-height) !important;
  color: black;
  z-index: calc(var(--bt-cms-appbar-action-z-index) + 1) !important;
  top: 0px !important;
  background-color: white !important;
  //box-shadow: 0px 3px 18px #0000001F;
}

.AppBarShadow {
  position: fixed;
  left: 0px;
  right: 0px;
  display: block;
  top: calc(var(--bt-cms-appbar-user-height) - 10px);
  z-index: calc(var(--bt-cms-appbar-z-index)) !important;
  height: 10px;
  box-shadow: 0px 3px 18px #0000001F;

}


.Toolbar {
  background-color: var(--standard-color-white);
  height: var(--bt-cms-appbar-user-height) !important;
  min-height: var(--bt-cms-appbar-user-height) !important;
  transition: background-color 0.3s ease-in;
}

.UserAvatar {
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
  color: var(--standard-jade-600);
}

.PopOver {
  transform: translateY(15px) !important;
}

.MenuItem {
  margin: 2px;
  font-size: 14px;
}

.NameInfoPanel {
  padding-inline: 10px;
  padding-block-start: 10px;
  padding-block-end: 5px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.OrgInfoPanel {
  padding-inline: 10px;
  padding-block-end: 10px;
  font-size: 12px;
  font-weight: normal;
  text-wrap: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.Icon {
  margin: unset;
  padding-inline-end: 10px;
  color: var(--standard-jade-500);
}

.LinkDivider {

}

.Link, Link:visited {
  display: block;
  font-size: 14px;
  padding-inline-start: 50px;
  padding-block: 10px;
  text-decoration: none;
  color: var(--text-color-light-gray);
  line-height: 16px;
  cursor: pointer;
  text-align: left;
  vertical-align: center;
}

.Link:hover {
  text-decoration: underline;
}

@media screen and (min-width: 760px) {
  .Link {
    display: none;
  }
  .LinkDivider {
    display: none;
  }
}

.Button {
  color: var(--bt-cms-color-primary);
}

.SidebarDivider {
  height: 50px;
}

.SidebarCompanyLogo {
  height: 30px;
  margin: auto;
}

.CompanyLogo {
  height: 30px;
}

.CompanyLogoSmall {
  height: 30px;
  display: none;
}

.CompanyLogoPanel {
  background-color: white;
  padding: 0.5rem;
  padding-block: 12px;
  padding-inline: 20px;
  margin: -25px;
}

.NewCompanyLogoPanel {
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  width: var(--bt-cms-sidebar-main-width);
  height: var(--bt-cms-appbar-user-height);
  background-color: var(--bt-cms-color-sidebar-dark);
}



.DownloadPanel {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-inline: 0.3rem;
  padding-block: 0.3rem;
  font-size: 0.8rem;
  text-align: center;
}

.UserInfoPanel {
  vertical-align: middle;
  display: block;
  font-size: 1rem;
  padding-inline-start: 40px;
  color: white;
  left: 100px;
  line-height: 1.5rem;
}

.OrgChoice {
  position: absolute;
  width: 370px;
  right: 70px;
  padding: 0px;
}

.PublishMenuPanel {
  position: absolute;
  right: calc(var(--bt-cms-org-width) + 30px);
}


.OrgChoiceText {
  color: black;
  line-height: 2em;
}

.OrgChoiceIcon {
  display: inline-block;
  line-height: 40px;
  vertical-align: bottom;
  font-size: 18px;
}

.OrgChoiceLabel {
  color: var(--standard-coal-400);
  display: inline-block;;
  line-height: 32px;
  font-size: 18px;
  padding-inline-start: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;;
  width: calc(100% - 30px);
}

.OrgLogo {
  //padding-top: 6px;
  color: rgba(0,0,0,0.54);
}

@media screen and (max-width: 900px) {
  .UserInfoPanel {
    display: none;
  }
}

@media screen and (max-width: 720px){

  .CompanyLogo {
    display: none;
  }

  .CompanyLogoSmall {
    display: inline;
    margin-inline-start: 10px;
  }


  .UserAvatar {
    background-color: transparent;
  }

  .OrgChoiceText {
    width: calc(100% - 70px);
  }

  .OrgLogo {
    //color: black;
  }

  .LinkDivider {
    display: none;
  }

  .PublishMenuPanel {
    display: none;
  }

}

.GlobalBuildButton {

}

.MenuButton {
  position: fixed;
  background-color: red;
}

.MenuLoginPanel {
  position: absolute;
  left: calc(var(--bt-cms-sidebar-main-width) + 15px);
}

.BreadCrumb {
  padding-inline-start: 5px;
}

.MenuLoginPanel > span {
  font-size: 20px;
  color: var(--standard-jade-500);
  font-weight: 600;
  padding-inline-start: 5px;
}

.SpanShiftUp {
  padding-block-end: 10px;
  vertical-align: text-bottom;
}

.MenuLoginPanel button {
  color: transparent;
  background-color: transparent;
  cursor: default;
}

.MenuLoginPanel button:hover {
  color: transparent;
  background-color: transparent;
  cursor: default;
}

@media screen and (max-width: 720px){

  .MenuLoginPanel > span {
    display: none;
  }

  .BreadCrumb {
    display: none;
  }

}

.NewOrgChoice {
  position: absolute;
  width: var(--bt-cms-org-width);
  right: 5px;
  padding: 0px;
}

.NewUserActionPanel {
  position: absolute;
  //right: 325px;
  right: calc(var(--bt-cms-org-width) - 5px);
  top: 12px;
  display: block;
}


@media screen and (max-width: 720px) {
  .OrgChoiceText {
    width: calc(100%);
  }

  .GlobalBuildButton {
    display: none;
  }

  .NewOrgChoice {
    position: absolute;
    width: calc(100% - var(--bt-cms-sidebar-main-width) - 20px);
    left: calc(var(--bt-cms-sidebar-main-width) + 10px);
  }

  .NewUserActionPanel {
    position: absolute;
    right: 10px;
    top: 12px;
    display: block;
  }

}


@media screen and (max-width: 500px){

  .OrgLogo {
    display: none;
    color: black;
  }




}

.BuildPanel {
  position: absolute;
  right: 80px;
  background: white;
  top: 10px;
}



.UserActionPanel {
  position: absolute;
  right: 10px;
  top: 0px;
}

//.UserButton {
//  border: 1px solid var(--standard-jade-300);
//  background-color: var(--standard-jade-100);
//  width:40px;
//  height: 40px;
//  color: var(--standard-jade-500);
//}
//
//
//.UserButton:hover {
//  background-color: var(--standard-jade-200);
//}


.Sidebar {
  text-align: center;
  padding-block-start: 1rem;
  box-sizing: content-box;
  position: fixed;
  background-color: var(--bt-cms-color-sidebar-dark);
  color: #fff;
  top: calc(var(--bt-cms-appbar-user-height));
  bottom: 0px;
  left: calc(var(--bt-cms-sidebar-move-left) - var(--bt-cms-sidebar-main-width));
  width: var(--bt-cms-sidebar-main-width);
  z-index:  calc(var(--bt-cms-drawer-z-index) + 1);
  overflow-x: hidden;
  overflow-y: auto;
  transition: left 0.3s ease-in;
}

.Timeline {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 200px;
  pointer-events: none;
  z-index: var(--bt-cms-timeline-z-index);
}

.PointerEventOff {
  pointer-events: none;
  background-color: red;
}


.Drawer {
  background-color: var(--bt-cms-color-drawer);//#004475;
  color: #fff;
  position: fixed;
  //bottom: var(--bt-cms-footer-height);
  bottom: 0px;
  top: 0px;//calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 2px);
  width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
  max-width: calc(var(--bt-cms-drawer-width) - (var(--bt-cms-drawer-padding) * 2));
  z-index:  calc(var(--bt-cms-drawer-z-index) + 2);
  overflow-y: auto;
  overflow-x: hidden;
  transition: opacity 0.3s ease-out;
  //box-shadow: 0px 12px 12px #00000029;
  box-shadow:  12px 0px 12px -12px #00000029;
}

.Drawer:focus {
  outline: none;
}

.SidebarTitle {
  margin: auto;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0;
}

.Drawer ul {
  padding-inline: 16px;
}

.Drawer li {
  color: var(--standard-coal-500);
  height:32px;
  line-height: 32px;
  padding-inline-start: 16px;
  margin-block-end: 10px;
  border-radius: 4px;
  box-shadow: none !important;
}

.DrawerTopAction {
  padding-block-start: 16px;
  padding-inline-start: 16px;
}

.DrawerTopAction span {
  color: #66737A;
  font-weight: 500;
  font-size: 14px;
}

.DrawerType {
  padding-block-start: 20px;
  padding-inline-start: 24px;
  font-size: 16px;
  font-weight: 600;
  color: var(--standard-coal-500);
}

.DrawerTopicTitle {
  background-color: transparent;
  display: inline-block;
  width: calc(100% - 24px);
}

.DrawerTopicTitle p {
  color: #66737A;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
  float: left;
  padding-inline-start: 24px;
  padding-inline-end: 8px;
}

.DrawerTopicTitle hr {
  display: inline-block;
  height: 0px;
  width: 115px;
  float: right;
  border: 1px solid #DBDBDB;
  size: 10px;
}

.ContentTitle {
  padding-block-start: 36px;
  text-transform: uppercase;
}

.SettingTitle {
  padding-block-start: 16px;
}


