.DefaultFontColor {
  color: #444446;
}

.AddMenuIcon {
  float: right;
}

.MenuTextField {
  padding-block-start: 0.7rem;
  padding-block-end: 0.2rem;
}

.MenuPanel {
  padding-block-start: 0.6rem;
  padding-block-end: 0.2rem;
}

.ColumnToggleButton {
  width: 100%;
}

.ColumnToggleButton button {
  width: 20%;
  height: 30px;
  padding: 0px;
}