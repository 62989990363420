body {
  margin: 0;
  font-family: 'Inter','Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: #666;
  background-color: var(--standard-smoke-400);
}

a {
  text-decoration: none;
}

