.Root {
  padding-inline: 4px;
  padding-block: 3px;
}

.Title {
  font-size: 12px;
  color: var(--standard-coal-400);
  line-height: 16px;
}

.Bold {
  font-weight: 700;
  color: var(--standard-coal-600);
}

.Button {
  background-color: white;
  width: 28px;
  height: 28px;
  border-radius:  3px;
  border: 1px solid lightgrey;
  padding: 0px;
  cursor: pointer;
  color: var(--secondary-text-color);

}

.Button span {
  font-size: 16px;
}

.Button:hover {
  color: var(--standard-jade-500);
  box-shadow: 0 2px 6px #00562D20;
}

.Popover {
  margin-block-start: 10px;
  width: 300px;
  min-height: 100px;
  padding: 5px;
}

.PopoverTitle {
  font-size: 12px;
  color: var(--standard-coal-500);
  font-weight: bold;
  padding-block-end: 8px;
  padding-inline: 10px;
}

.PopoverDetail {
  padding-block-start: 10px;
  padding-block-end: 5px;
}

.TargetInput {
  padding-block-start: 10px;
}