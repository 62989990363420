.Root {
  min-width: 330px;
  max-width: 330px;
  width: 330px;
  margin-inline: auto;
  cursor: pointer;
  transition: 0.2s all;

  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000024;
  border-radius: 10px;
}


.Root:hover {
  outline: 8px solid #ac66ff;
  outline-offset: -8px;
}

.CircleButton {
  background: #E5F3EE 0% 0% no-repeat padding-box;
  border: 1px solid #A1BFC4;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-inline-start: 70px;
  display: flex;
}

.CircleButton span {
  margin: auto;
  color: var(--standard-jade-500);
}

.NewLabelPanel {
  padding-inline-start: 8px;
  padding-block: 8px;
  max-width: 320px;

}
.NewLabelTitle {
  color: var(--standard-coal-400);
  font-size: 12px;
  align-items: center;
  display: block;
  padding-block-start: 5px;
}

.RootSelected {
  outline: 8px solid #ac66ff;
  outline-offset: -8px;
}


.ActionPanel {
  float: right;
  display: flex;
  width: 120px;
  padding-block-start: 13px;
  visibility: hidden;
}

.ActionPanelSelected {
  visibility: unset;
}


.Root:hover .ActionPanel {
  visibility: unset;
}




.Content {
  height: 50px;
  padding-block: 0px;
  padding-inline: 16px;
}


.RootSmall {
  margin-block-start: 10px;
  min-width: 190px;
  max-width: 190px;
  width: 190px;
}

.Title {
  color: var(--standard-coal-500);
  padding-block-start: 16px;
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 60%;
  font-weight: 500;
}

.Media {
  background: var(--standard-coal-100);
  //background: linear-gradient(var(--bt-cms-color-primary-normal) 10%, var(--bt-cms-color-primary-dark) 90%);
}



.Media image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}


.SubTitle {
  color: #66737A;
  font-size: 12px;
  line-height: 15px;
  padding-block-start: 8px;
  height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
}

.LayoutTitle {
  float: right;
  padding-block-start: 16px;
  color: #66737A;
  font-size: 12px;
  line-height: 15px;
}


.ContentSmall {
  height: 10px;
}

.LayoutTitleSmall {
  float: right;
  padding-block-start: 0px;
  color: #66737A;
  font-size: 12px;
  line-height: 15px;
}

.TitleSmall {
  color: var(--standard-coal-500);
  padding-block-start: 0px;
  padding-block-end: 8px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 60%;
}


