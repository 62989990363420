.footer_root {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  bottom: 0px;
  align-items: center;
  justify-content: space-between;
  font-size: 12.5px;
  width: 100%;
  background-color: var(--bt-cms-footer-background-color);
  height: var(--bt-cms-footer-height);
  padding-inline-start: calc(var(--bt-cms-sidebar-move-left) + 0px);
  padding-inline-end: 0px;
  z-index: var(--bt-cms-footer-z-index);
  transition: padding-inline-start 0.3s ease-in;
  color: var(--standard-coal-400);
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer_panel {
  margin-inline: auto;
  margin-block: 0px;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 50px);
  padding-inline: 0px;
  border-top: 1px solid var(--standard-coal-200);
}

.detail_label , .version_label,  .version_label:visited {
  font-size: 14px;
  text-decoration: none;
  color: var(--text-color-light-gray);
  line-height: 14px;
  cursor: pointer;
}

.version_label:hover {
  text-decoration: underline;
}

.footer_image {
  display:inline-block;
  height:25px;
  top: 5px;
  position: relative;
}

.left {
  flex: 60%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.right {
  flex: 40%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}


.left > ul , .right > ul {
  list-style: none;
  margin-inline: 0px;
  padding-inline: 0px;
  display: flex;
  flex-wrap: nowrap;
}

.left > ul > li , .right > ul > li {
  display: inline-block;
  text-overflow: ellipsis;
  text-wrap: none;
  white-space: nowrap;
}

.left > ul > li {
  padding-inline-end: 15px;
  align-items: start;
}

.right > ul > li {

  padding-inline-start: 15px;
  align-items: end;
  justify-content: end;
}


.inline_block {
  display: inline-block;
}

.hideable_panel {

}

@media screen and (max-width: 760px) {
  .hideable_panel {
    display: none;
  }

  .footer_image {
    height: 20px;
  }
}


