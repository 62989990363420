.Root {
  position: relative;
  //height: 150px;
}

.Editor, .Highlighting {
  //background-color: #f6f6f6;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  font-size: 1.0rem;
  font-family: monospace;
  line-height: 20pt;
  padding:0px;
  margin: 0px;
  border: 0;
  width: calc(100%);
  height: 100%;
  overflow: auto;
  resize: none;
}

.Editor:focus {
  outline: none;
}


.Editor {
  white-space: nowrap;
  z-index: 55;
  color: transparent;
  background-color: transparent;
  caret-color: #313539
}

.Highlighting {
  color: #313539;
  z-index: 50;
}
