.Container {
  clip: auto;
  overflow: hidden;
  height: 100%;
}

.TitlePanel {
  margin: auto;
}

.DescPanel {
  margin: auto;
}