.Root {
  width: 100%;
  height: 100%;
  min-height: 3rem;
  box-sizing: border-box;
}

.Menu {
  display: inline-block;
  position: relative;
  padding: 10px;
  text-transform: uppercase;
}

.Menu > a::after {
  content: "▼";
  display: inline-block;
}

.Menu:hover > a::after {
  content: "▲";
  display: inline-block;
}

.Link {
  display: inline-block;
  position: relative;
  padding: 10px;
  text-transform: uppercase;
}

.Link > a::after {
  display: inline-block;
}

.Link:hover > a::after {
  display: inline-block;
}

.Menu ul {
  display: block ;
  background-color: white;
  border: 1px solid transparent;
  position: absolute;
  max-height: 0px;
  min-height: 0px;
  min-width: 200px;
  overflow: hidden;
  width:400px;
  left: 0px;
  box-sizing: border-box;
  z-index: 100;
  opacity: 0.0;
  transition: border,min-height 0.3s  ease-in;
}

.Menu:hover ul {
    border: 1px solid gray;
    display:block;
    overflow-y: auto;
    min-height: 200px;
    z-index: 101;
    opacity: 1.0;
    padding-inline-start: 10px;
    padding-block-start: 10px;
}

.Menu ul li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}



.SearchTextField {
  width: 310px;
  height: 40px;
  font-size: 13px;
  line-height: 1.2;
  padding: 0 45px 0 12px;
  border: 1px solid #bbbbbb;
}

.TopSection {

}

.Section div {
  display: inline-block;
}

.ClearSection {
  display: block;
  clear: both;
}


.TopSection {

}

.TopSection > div+div {
  padding-inline: 20px;
}

.MiddleSection {

}

.BottomSection {

}

.ShopcartPanel {
  padding-inline: 0px;
  height: 22px;
}

.ShopcartPanel img {
  width: 19px;
  height: 19px;
}

.ShopcartPanel img,span,a {
  vertical-align: middle;
}

.ShopcartPanel a+a {
  padding-inline-start: 10px;
}

.LoginPanel {
  padding-inline: 0px;
  height: 22px;
}

.LoginPanel a,span {
  vertical-align: middle;
}

.LoginPanel a+a {
  padding-inline-start: 10px;
}

.SmallBar {
  max-height: 150px;
  transition: padding 0.3s ease-in;
  border-block-end: 1px solid lightgrey;
}

.SmallBarFixed {
  position: fixed;
  height: 65px;
  width: 100%;
  background-color: white;
  padding-block-start: 10px;
  border-block-end: 1px solid lightgrey;
  transition: padding 0.3s ease-in;
  z-index: 50;
}

.SmallLogo {
  display: flex;
  position: absolute;
  top:0;
  width: 100%;
  padding-inline-start: 60px;
  padding-inline-end: 75px;
  padding-block-start: 10px;
  bottom: 150px;
  height: 50px;
  box-sizing: border-box;
}

.SmallLogo img {
  margin: auto;
  width: 100%;
  height: auto;
  max-height: 60px;
  object-fit: contain;
  object-position: center;
}

.SmallCart {
  z-index: 50;
  position: fixed;
  line-height: 1.4;
  right: 0.8em;
  //z-index: 16000;
  font-size: 28px;
}

.SmallCart i {
  display: inline-block;
  width: 30px;
}

.SmallCart a span {
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  top: -4px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 17px;
  width: 22px;
  height: 22px;
  padding: 2px;
  border: none;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  //z-index: 10000;
}

.SmallCartIcon {
  color: #296ebb;
}

.SmallCartLabel {
  background-color: #296ebb;
}

.SmallHamburgerMenuPanel {
  position: fixed;
  max-width: 30px;
  max-height: 30px;
  display: inline-block;
  z-index: 50;
}

.SmallHamburgerMenu,
.SmallHamburgerMenu::before ,
.SmallHamburgerMenu::after  {
  background-color: #e8682a;

}

.Search {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Search input {
  display: block;
  position: relative;
  left: 0px;
  right: 0px;
  padding-inline-end: 65px;
}

.Search span:nth-of-type(1) {
  position: relative;
  font-size: 20px;
  top: -32px;
  left: calc(100% - 60px);
  color: black;
  cursor: pointer;
}

.Search span:nth-of-type(2) {
  position: relative;
  font-size: 20px;
  top: -32px;
  left: calc(100% - 55px);
  color: #296ebb;
  cursor: pointer;
}


.SmallSearchHidden {
  height: 90px;
}

.SmallSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-block-start: 90px;
}

.SmallSearch input {
  width: 70%;
  margin-inline-start: 30px;
  padding-inline-end: 60px;
  display: inline-block;
  margin: auto;
}

.SmallSearch span:nth-of-type(1) {
  position: relative;
  font-size: 20px;
  top: -30px;
  left: calc(35% - 5px);
  color: black;
  cursor: pointer;
}

.SmallSearch span:nth-of-type(2) {
  position: relative;
  font-size: 20px;
  top: -50px;
  left: calc(35% + 20px);
  color: #296ebb;
  cursor: pointer;
}

.SmallSearchFixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: 3px;
  position: absolute;
  width: calc(100% - 300px);
  padding-block-start: 3px;
  padding-block-end: 8px;
  padding-inline-start: 100px;
}

.SmallSearchFixed input {
  width: 100%;
  margin-inline-start: 10px;
  padding-inline-end: 70px;
  display: inline-block;
  margin: auto;
}

.SmallSearchFixed span:nth-of-type(1) {
  position: relative;
  font-size: 20px;
  top: -30px;
  left: calc(50% + 35px);
  color: black;
  cursor: pointer;
}

.SmallSearchFixed span:nth-of-type(2) {
  position: relative;
  font-size: 20px;
  top: -50px;
  color: black;
  left: calc(50% + 65px);
  color: #296ebb;
  cursor: pointer;
}

.SmallMenu {
  position: relative;
  top: -1px;
  left: 0px;
  right: 0px;
  //height: 0px;
  z-index: 1000;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in;
}

.SmallMenu ul {
  display: block;
  background-color: white;
}

.SmallMenu a:hover {
  text-decoration: none;
}

.SmallMenu li {
  vertical-align: top;
  list-style: none;
  font-size: 18px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;

  display: block;
  border-bottom: 1px solid lightgrey;
  text-transform: uppercase;
  cursor: pointer;
}

.SmallMenu li:hover {
  background-color: #296ebb;
  font-weight: 400;
  color: white;

}