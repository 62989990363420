.Root {
  position: absolute;
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + 1px ) !important;
  bottom: 0px;
  width: calc(100% - var(--bt-cms-sidebar-move-left));
  border: 0px;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100% - 0px - (var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height)  + var(--bt-cms-footer-height) + 2px));
  transition: all 0.3s ease-in;
  background-color: transparent;
  display: flex;
  padding-block-start: 0px;
  box-sizing: border-box;
}

.LFrame {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 10px;
  right: 10px;
  border: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  max-width: 2000px;
  margin-inline: auto;
  margin-block: auto;
  height: 100%;
  transition: all  0.3s ease-in;
}

.TemplateDialog {

}

.TemplateDialog > div > div {
  max-width: unset;
  box-sizing: border-box;
  height: 90%;
  max-height: 900px;
}

.IFrame {
  border: 0px;
  box-shadow: 0px 2px 6px #0000001F;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  max-width: 2000px;
  margin-inline: auto;
  margin-block: auto;
  height: 100%;
  transition: all  0.3s ease-in;
}

.StorefrontCombobox {
  margin-block-start: 3px;
}

.StorefrontCombobox div {
  padding-block: 2px;
}

.Drawer {
  background-color: white;
  position: fixed;
  bottom: 0px;//bottom: var(--bt-cms-footer-height);
  //top:  calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + var(--bt-cms-appbar-action-height) + 1px);
  top:  calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height)  + 1px);
  right: 0px;
  left: 0px;
  width: var(--bt-cms-setting-drawer-width);
  box-sizing: border-box;
  z-index: calc(var(--bt-cms-drawer-z-index) - 1);
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s ease-in;
  padding-block-start: 0.8rem;
  padding-block-end: 0.3rem;
  padding-inline: 0.3rem;

}

.TopMenuPanel {
  position: fixed;
  width: fit-content;
  height: 50px;
  top: 12px;
  right: calc(var(--bt-cms-org-width) + 85px);
  z-index: calc(var(--bt-cms-appbar-action-z-index) + 2);
}

@media screen and (max-width: 900px) {
  .TopMenuPanel {
    display: none;
  }
}

.DrawerCloseIcon {
  position: absolute !important;
  top: 5px !important;
  right: 10px !important;
}

.ContentContainer {
  position: absolute;
  top: calc(var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + var(--bt-cms-appbar-action-height));
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - (var(--bt-cms-appbar-user-height) + var(--bt-cms-appbar-page-height) + var(--bt-cms-appbar-action-height)));
  transition: margin 0.3s ease-in;
}


.NewSectionPanel {
  height: 4rem;
  background-color: var(--standard-smoke-500);
}

.Selection {
  height: 30px;
  max-height: 30px;
}

.TopPanel {
  //outline: 1px solid lightgrey;
  //outline-offset: 1px;
  box-shadow: 5px 0 5px -4px var(--standard-coal-200), -5px 0 5px -4px var(--standard-coal-200);
}