.Root {
  width: 60px;
  display: block;
}


.IconPanel {
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  background-color: #E5F3EE;
  border: 1px solid #7FC2AB;
  width: 60px;
  height: 60px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}


.IconPanel:hover {
  border: 1px solid var(--standard-jade-500);
}

.Label {
  display: block;
  color: #66737A;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  padding-block-start: 5px;
}
