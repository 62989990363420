.DialogRootFixHeight div[role=dialog] {
  width: 80%;
  height: 80%;
}

.DialogRoot div[role=dialog] {
  width: 80%;
}

.Button {
  padding-inline-start: 1.3rem;
  padding-inline-end: 1.3rem;
}

.Button:disabled {
  color: white !important;
}

.TabContent {
  padding-block-start: 8px;
  position: absolute;
  top: 110px;
  left: 0;
  bottom: 65px;
  right: 0px;
  overflow: hidden;
  overflow-y: auto;
}

.FormPanel {
  padding-block-start: 16px;
}
.FormLabel {
  color: var(--standard-coal-400);
  font-size: 14px;
  padding-inline-end: 5px;
}

.ButtonInlineSpace {
  margin-inline-start: 1rem !important;
}

.IconSize {
  font-size: 18px;
}

.DialogContent {
  padding: 0.5rem 0.5rem;
  min-width: 30rem;
}

.CloseIcon {
  float: right;
}

@media screen and (max-width: 600px){
  .DialogContent {
    min-width: unset;
  }
}

.HTMLInput {
  color: black;
  background: white;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}

.CheckBox {
  color: black;
}

.SelectIcon {
  max-height: 20px;
  min-height: 20px;
  vertical-align: middle;
}